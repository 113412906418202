/* ==========================================================================
   =Pagination
   ========================================================================== */

   .pagination {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
   }

   .pagination li {
      margin: 0 0.5rem 0.25rem 0;
   }

   .pagination li a {
      border: 1px solid var(--color-border);
      border-radius: var(--border-radius);
      line-height: 2rem;
      display: block;
      padding: 0 0.5rem;
      text-decoration: none;
      min-width: 2.35rem;
      text-align: center;
   }

   .pagination li a span {
      display: none;
   }

   .pagination li:hover a {
      background-color: var(--color-accent-1);
      border-color: var(--color-accent-1);
      color: #ffffff;
   }

   .pagination li.active a {
      background-color: var(--color-accent-2);
      border-color: var(--color-accent-2);
      color: #ffffff;
   }

   @media(min-width: 360px){

      .pagination li a {
         padding: 0 0.75rem;
      }      

   }

   @media(max-width: 575px){

      .pagination {
         justify-content: space-between;
      }

      .pagination li {
         display: none;
      }

      .pagination li.page-indicator {
         display: list-item;
      }  

      .pagination li a span {
         display: inline;
      }

   }   