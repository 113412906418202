/* ==========================================================================
   =Typography
   ========================================================================== */

   /* source-sans-3-300 - latin
	@font-face {
		font-display: swap; 
		font-family: 'Source Sans 3';
		font-style: normal;
		font-weight: 300;
		src: url('../fonts/source-sans/source-sans-3-v9-latin-300.woff2') format('woff2'); 
	}

	 */
	
	/* source-sans-3-regular - latin */
	@font-face {
		font-display: swap; 
		font-family: 'Source Sans 3';
		font-style: normal;
		font-weight: 400;
		src: url('../fonts/source-sans/source-sans-3-v9-latin-regular.woff2') format('woff2'); 
	}
	
	/* source-sans-3-500 - latin 
	@font-face {
		font-display: swap; 
		font-family: 'Source Sans 3';
		font-style: normal;
		font-weight: 500;
		src: url('../fonts/source-sans/source-sans-3-v9-latin-500.woff2') format('woff2'); 
	}

	*/
	
	/* source-sans-3-700 - latin */
	@font-face {
		font-display: swap; 
		font-family: 'Source Sans 3';
		font-style: normal;
		font-weight: 700;
		src: url('../fonts/source-sans/source-sans-3-v9-latin-700.woff2') format('woff2'); 
	}
	
  
	html {
		font-size: 106.25%; /* 17px */
	} 
	
	body {
		font-family: 'Source Sans 3', sans-serif;
		font-weight: 400;
		line-height: 1.75;
		color: var(--color-text);
	}
	
	h1,
	h2, 
	h3, 
	h4, 
	h5, 
	h6,
	.h1,
	.h2, 
	.h3, 
	.h4, 
	.h5, 
	.h6 {
		margin: 1.38rem 0 1.38rem;
		font-family: 'Source Sans 3', sans-serif;
		font-weight: 400;
		line-height: 1.3;
		color: var(--color-headings);
	}

	h1,
	.h1 {
	  font-size: 1.383rem;
	}

	h2,
	.h2 {
		font-size: 1.296rem;
	}

	h3,
	.h3 {
		font-size: 1.215rem;
	}

	h4,
	.h4 {
		font-size: 1.138rem;
	}

	h5,
	.h5 {
		font-size: 1.067rem;
	}
	
	h6,
	.h6 {
		font-size: 1rem;
	}
	
	h1:first-child,
	h2:first-child,
	h3:first-child,
	h4:first-child, 
	h5:first-child, 
	h6:first-child { 
		margin-top: 0 !important; 
	}

	@media (min-width: 768px) {
	
		h1,
		.h1 {
		  font-size: 1.802rem;
		}

		h2,
		.h2 {
			font-size: 1.602rem;
		}

		h3,
		.h3 {
			font-size: 1.424rem;
		}

		h4,
		.h4 {
			font-size: 1.266rem;
		}

		h5,
		.h5 {
			font-size: 1.125rem;
		}
		
		h6,
		.h6 {
			font-size: 1rem;
		}
	
	}
	
	p { margin-bottom: 1rem; }

	small { font-size: 0.8rem; }
	
	big { font-size: 1.125rem; }

/* =Horizontal Ruler
   ========================================================================== */

	hr {
		margin: 1rem 0;
		border-color: var(--color-border);
	}

	@media(min-width: 768px) {

		hr {
			margin: 2rem 0;
		} 

	}
	
/* =Images
   ========================================================================== */

	img { 
		max-width: 100%;
		height: auto;
		border: none; 
		vertical-align: middle;
	}
	
/* =Lists
   ========================================================================== */
   	

	
/* =Tables
   ========================================================================== */

	table {
		width: 100%;
		border: 1px solid var(--color-border);
		border-collapse: collapse;
		border-spacing: 0;
		border-radius: var(--border-radius);
		margin-bottom: 1rem;
		table-layout: fixed;
		overflow-x: scroll;
	}

	table caption {
		margin-bottom: 1rem;
		text-align: left;
	}

	table th {
		padding: 0.75rem; 
		border: 1px solid var(--color-border);
		font-weight: 700;
		text-align: left;
	}

	table td { 
		padding: 0.75rem;  
		border: 1px solid var(--color-border);
		text-align: left;
	}	
	
/* =Links
   ========================================================================== */

	a,
	a:visited {
		color: var(--color-link);
		text-decoration: underline;
		outline: 0;
		transition: color 0.15s;
	}

	a:focus  { color: var(--color-link-hover); }
	a:hover  { color: var(--color-link-hover); }
	a:active { color: var(--color-link-hover); }	
	