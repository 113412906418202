/* ==========================================================================
   =Profile Sticky
   ========================================================================== */

    #profile-sticky {
        color: var(--color-text);
        background-color: var(--color-accent-4);
        padding: 0.75rem 0.5rem 0.75rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1000;
        font-size: 0.85rem;
        text-transform: uppercase;
    }

    #profile-sticky a {
        text-decoration: none;
        font-size: 1.25rem;
    }

    @media (min-width: 576px) {
    
        #profile-sticky {
            padding: 1rem 0 1rem;
        }    

    }

    @media (min-width: 1200px) {
    
        #profile-sticky {
            display: none;
        }    

    }

