/* ==========================================================================
   =Filter listings
   ========================================================================== */

   .filter-overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(24, 24, 27, 0.75);
      z-index: 9999;
   }
  
   .filter {
      position: fixed;
      top: 0;
      left: 0;
      width: 320px;
      height: 100%;
      background-color: #fff;
      z-index: 10000;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

      height: 100%; 
      display: flex;
      flex-direction: column;
   }   

   .filter hr {
      margin: 1rem -1rem;
   }

   .filter label {
      font-weight: 700;
      font-size: 0.75rem;
      text-transform: uppercase;
   }

   .filter-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--color-border);
      padding: 0.5rem 1rem;
   }

   .filter-close {
      font-size: 1.5rem;
      cursor: pointer;
      color: var(--color-accent-1);
   }

   .filter-close:hover {
      color: var(--color-accent-2);
   }

   .filter-content {
      overflow-y: auto;
      padding: 1rem;
   }

   /* Active filters */

   .filters-active {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
   }

   .filters-active li {
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
   }

   .filters-active a {
      background-color: var(--color-accent-1);
      color: #ffffff;
      display: inline-block;
      padding: 0.125rem 0.5rem 0.125rem 0.5rem;
      text-decoration: none;
      font-size: 0.75rem;
   }

   .filters-active a:hover {
      background-color: var(--color-accent-2);
   }

   
   @media(min-width: 768px) {

      .filters-active li {
         margin-right: 0.5rem;
      }
      
      .filters-active a {
         padding: 0.25rem 1rem;
         font-size: 0.85rem;
      }

   }


