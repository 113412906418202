/* ==========================================================================
   =Variables
   ========================================================================== */

	:root{
		
		--color-text: 			    #323232;
		--color-muted: 			    #555555;
			
		--color-success:			#00796B;
		--color-danger:				#C62828;
		--color-warning:			yellow;			

		--color-whatsapp:			#075E54;

		--color-headings: 			#262626;
			
		--color-accent-1: 			#B24572; // pink, old value #c25582 
		--color-accent-2: 			#342849; // light purple
		--color-accent-3: 			#181221; // dark purple
		--color-accent-4: 			#100C16; 
			
		--color-border: 		 	#dddddd;
		--color-background: 	 	#f4f4f4; 
			
		--color-labels: 		 	var(--color-text);
		--color-placeholders: 		var(--color-text-muted);
		--color-border-forms:		var(--color-border);
		--color-border-forms-focus: var(--color-accent-2);

		--color-link:				var(--color-accent-1);
		--color-link-hover:			var(--color-accent-2);	

		--border-radius:			0;

	}

	#cta,
	#header,
	#page-header,
	#footer,
	#copyright {

		--color-text: 			    #fffc;
		--color-muted: 			    #666666;
			
		--color-headings: 			#ffffff;

		--color-border: 		 	#494949;
		--color-background: 	 	#000000; 

		--color-link:				#fffc;
		--color-link-hover:			#ffffff;	

	}

	#profile-sticky {
		--color-text: 			    #ffffff;

		--color-link:				var(--color-accent-1);
		--color-link-hover:			var(--color-accent-1);
	}