/* APP CSS */ /* ==========================================================================
   =Reset (minimal)
   ========================================================================== */
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 1;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul {
  padding-left: 0;
  list-style-position: inside;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
  padding-left: 2rem;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

/* ==========================================================================
   =Variables
   ========================================================================== */
:root {
  --color-text: #323232;
  --color-muted: #555555;
  --color-success:	#00796B;
  --color-danger:	#C62828;
  --color-warning:	yellow;
  --color-whatsapp:	#075E54;
  --color-headings:	#262626;
  --color-accent-1:	#B24572;
  --color-accent-2:	#342849;
  --color-accent-3:	#181221;
  --color-accent-4:	#100C16;
  --color-border:	#dddddd;
  --color-background:	#f4f4f4;
  --color-labels:	var(--color-text);
  --color-placeholders:	var(--color-text-muted);
  --color-border-forms:	var(--color-border);
  --color-border-forms-focus: var(--color-accent-2);
  --color-link:	var(--color-accent-1);
  --color-link-hover:	var(--color-accent-2);
  --border-radius:	0;
}

#cta,
#header,
#page-header,
#footer,
#copyright {
  --color-text: #fffc;
  --color-muted: #666666;
  --color-headings:	#ffffff;
  --color-border:	#494949;
  --color-background:	#000000;
  --color-link:	#fffc;
  --color-link-hover:	#ffffff;
}

#profile-sticky {
  --color-text: #ffffff;
  --color-link:	var(--color-accent-1);
  --color-link-hover:	var(--color-accent-1);
}

/* ==========================================================================
   =Typography
   ========================================================================== */
/* source-sans-3-300 - latin
@font-face {
	font-display: swap; 
	font-family: 'Source Sans 3';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/source-sans/source-sans-3-v9-latin-300.woff2') format('woff2'); 
}

 */
/* source-sans-3-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans/source-sans-3-v9-latin-regular.woff2") format("woff2");
}
/* source-sans-3-500 - latin 
@font-face {
	font-display: swap; 
	font-family: 'Source Sans 3';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/source-sans/source-sans-3-v9-latin-500.woff2') format('woff2'); 
}

*/
/* source-sans-3-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans/source-sans-3-v9-latin-700.woff2") format("woff2");
}
html {
  font-size: 106.25%; /* 17px */
}

body {
  font-family: "Source Sans 3", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 1.38rem 0 1.38rem;
  font-family: "Source Sans 3", sans-serif;
  font-weight: 400;
  line-height: 1.3;
  color: var(--color-headings);
}

h1,
.h1 {
  font-size: 1.383rem;
}

h2,
.h2 {
  font-size: 1.296rem;
}

h3,
.h3 {
  font-size: 1.215rem;
}

h4,
.h4 {
  font-size: 1.138rem;
}

h5,
.h5 {
  font-size: 1.067rem;
}

h6,
.h6 {
  font-size: 1rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0 !important;
}

@media (min-width: 768px) {
  h1,
.h1 {
    font-size: 1.802rem;
  }
  h2,
.h2 {
    font-size: 1.602rem;
  }
  h3,
.h3 {
    font-size: 1.424rem;
  }
  h4,
.h4 {
    font-size: 1.266rem;
  }
  h5,
.h5 {
    font-size: 1.125rem;
  }
  h6,
.h6 {
    font-size: 1rem;
  }
}
p {
  margin-bottom: 1rem;
}

small {
  font-size: 0.8rem;
}

big {
  font-size: 1.125rem;
}

/* =Horizontal Ruler
   ========================================================================== */
hr {
  margin: 1rem 0;
  border-color: var(--color-border);
}

@media (min-width: 768px) {
  hr {
    margin: 2rem 0;
  }
}
/* =Images
   ========================================================================== */
img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}

/* =Lists
   ========================================================================== */
/* =Tables
   ========================================================================== */
table {
  width: 100%;
  border: 1px solid var(--color-border);
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  table-layout: fixed;
  overflow-x: scroll;
}

table caption {
  margin-bottom: 1rem;
  text-align: left;
}

table th {
  padding: 0.75rem;
  border: 1px solid var(--color-border);
  font-weight: 700;
  text-align: left;
}

table td {
  padding: 0.75rem;
  border: 1px solid var(--color-border);
  text-align: left;
}

/* =Links
   ========================================================================== */
a,
a:visited {
  color: var(--color-link);
  text-decoration: underline;
  outline: 0;
  transition: color 0.15s;
}

a:focus {
  color: var(--color-link-hover);
}

a:hover {
  color: var(--color-link-hover);
}

a:active {
  color: var(--color-link-hover);
}

/* ==========================================================================
   =Forms
   ========================================================================== */
::placeholder {
  color: var(--color-placeholders);
}

fieldset {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.25rem;
  color: var(--color-labels);
}

label + label {
  margin-top: -0.125rem;
}

@media (min-width: 768px) {
  fieldset {
    padding: 2rem 2rem;
  }
}
@media (min-width: 360px) {
  fieldset {
    padding: 1.5rem 1.5rem;
  }
}
/* =Inputs and Text Areas
   ========================================================================== */
input:not([type=checkbox]):not([type=radio]),
textarea {
  display: block;
  width: 100%;
  border-radius: var(--border-radius);
  padding: 0.25rem 0.75rem;
  border: 1px solid var(--color-border-forms);
  color: var(--color-text);
  appearance: none;
  background-clip: padding-box;
}

input[type=file] {
  padding: 0 !important;
  border: 0 !important;
}

textarea[rows] {
  height: auto;
}

input:not([type=checkbox]):not([type=radio]):not([type=file]):focus,
textarea:focus {
  border-color: var(--color-border-forms-focus);
  outline: 1px solid var(--color-border-forms-focus);
}

input[type=file]:focus {
  outline: 0;
}

input:not([type=checkbox]):not([type=radio]):not([type=file]):hover,
textarea:hover {
  border-color: var(--color-border-forms-focus);
}

/* =Select
   ========================================================================== */
select {
  display: block;
  width: 100%;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: var(--color-text);
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 0.75rem;
  border: 1px solid var(--color-border-forms);
  border-radius: var(--border-radius);
  appearance: none;
  background-clip: padding-box;
  cursor: pointer;
}

select[multiple],
select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

select:hover {
  border-color: var(--color-border-forms-focus);
}

select:focus {
  border-color: var(--color-border-forms-focus);
  outline: 1px solid var(--color-border-forms-focus);
}

/* =Buttons
   ========================================================================== */
button {
  padding: 0.3rem 1.75rem;
  border-radius: var(--border-radius);
  background-color: var(--color-accent-1);
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  border: 0;
  margin-bottom: 0.5rem;
}

button.btn-block {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

button.btn-large {
  padding: 0.5rem 1.75rem;
}

button:hover {
  background-color: var(--color-accent-2);
}

button i:first-child {
  margin-right: 0.5rem;
}

button i:last-child {
  margin-left: 0.5rem;
}

/* =Dropzone
   ========================================================================== */
/* =Validation
   ========================================================================== */
.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: red;
  font-weight: 700;
}

.err {
  color: var(--color-danger);
  font-size: 0.85rem;
}

.is-invalid {
  border-color: var(--color-danger) !important;
  outline-color: var(--color-danger) !important;
}

/* ==========================================================================
   =Body
   ========================================================================== */
body.admin {
  background-color: var(--color-background);
}

/* ==========================================================================
   =Header
   ========================================================================== */
#header {
  padding: 0 0.5rem;
  color: var(--color-text);
  background-color: var(--color-accent-3);
  height: 4.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  z-index: 1000;
}

@media (min-width: 576px) {
  #header {
    padding: 0;
    height: 5rem;
  }
}
@media (min-width: 1200px) {
  #header {
    height: 5rem;
  }
}
/* =Favorites
   ========================================================================== */
#user-favorites-trigger {
  text-decoration: none;
  margin-right: 1.25rem;
}

#user-favorites-trigger i {
  font-size: 1.5rem;
}

#user-favorites-trigger span {
  display: none;
}

@media (min-width: 576px) {
  #user-favorites-trigger span {
    display: inline-block;
    padding: 0 0 0 0.5rem;
  }
}
/* =Account Login
   ========================================================================== */
#user-account-trigger {
  text-decoration: none;
}

#user-account-trigger i {
  font-size: 1.5rem;
}

#user-account-trigger span {
  display: none;
}

@media (min-width: 576px) {
  #user-account-trigger span {
    display: inline-block;
    padding: 0 0 0 0.5rem;
  }
}
/* =Register Account
   ========================================================================== */
#user-register-trigger {
  text-decoration: none;
  margin-left: 1.25rem;
  padding: 0.2rem 0.75rem;
  border-radius: var(--border-radius);
  background-color: var(--color-accent-1);
  color: #ffffff;
}

#user-register-trigger:hover {
  background-color: var(--color-accent-2);
}

#user-register-trigger i {
  font-size: 1.5rem;
}

#user-register-trigger span {
  display: inline-block;
  padding: 0 0 0 0.5rem;
}

/* ==========================================================================
   =Content
   ========================================================================== */
#content {
  margin-top: 4.5rem;
  padding: 1.5rem 0.5rem 1rem;
}

.admin #content {
  padding: 0 0.5rem 0;
  margin-top: 0;
}

@media (min-width: 576px) {
  #content {
    margin-top: 5rem;
    padding: 2.5rem 0 2rem;
  }
  .admin #content {
    padding: 0;
  }
}
@media (min-width: 1200px) {
  #content {
    margin-top: 5rem;
  }
}
/* =Page Header
   ========================================================================== */
#page-header {
  margin: -1.5rem -0.5rem 1.5rem;
  padding: 1.5rem 0.5rem 1.5rem;
  background-color: var(--color-accent-1);
}

#page-header h1 {
  margin: 0;
}

@media (min-width: 576px) {
  #page-header {
    margin: -2.5rem 0 2rem;
    padding: 1.5rem 0 1.5rem;
  }
}
@media (min-width: 1200px) {
  #page-header {
    margin: -2.5rem 0 2.5rem;
    padding: 2rem 0 2rem;
  }
}
/* =CTA
   ========================================================================== */
#cta {
  margin: -1.5rem -0.5rem 1.5rem;
  padding: 1rem 0.5rem 1rem;
  background-color: var(--color-accent-3);
}

#cta .btn {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  #cta {
    margin: -2rem 0 2rem;
    padding: 1.5rem 0 1.5rem;
  }
}
@media (min-width: 768px) {
  #cta {
    display: none;
  }
}
/* =Locations
   ========================================================================== */
#locations {
  background-color: var(--color-background);
  margin: -1.5rem -0.5rem 1.5rem;
  padding: 1.5rem 0.5rem 0.5rem;
}

@media (min-width: 576px) {
  #locations {
    margin: -2rem 0 2rem;
    padding: 2.5rem 0 1.5rem;
  }
}
@media (min-width: 1200px) {
  #locations {
    margin: -2.5rem 0 2.5rem;
    padding: 3rem 0 2rem;
  }
}
/* ==========================================================================
   =Footer & Copyright
   ========================================================================== */
/* =Footer
   ========================================================================== */
#footer {
  padding: 2.5rem 0.5rem;
  color: var(--color-text);
  background-color: var(--color-accent-3);
}

@media (min-width: 576px) {
  #footer {
    padding: 3.5rem 0 1.5rem;
  }
}
/* =Copyright
   ========================================================================== */
#copyright {
  padding: 1rem 0;
  color: var(--color-text);
  background-color: var(--color-accent-4);
}

@media (min-width: 1200px) {
  #copyright {
    text-align: center;
  }
}
/* ==========================================================================
   =Alerts
   ========================================================================== */
.alert {
  margin-bottom: 1rem;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  padding: 1rem;
}

.alert i {
  color: var(--color-accent-1);
  margin-right: 0.25rem;
}

.alert ul {
  list-style: none;
}

/* ==========================================================================
   =Badges
   ========================================================================== */
.badge {
  color: #ffffff;
  border-radius: var(--border-radius);
  padding: 0 0.5rem;
  background-color: var(--color-accent-1);
  line-height: 1.5;
}

/* ==========================================================================
   =Breadcrumbs
   ========================================================================== */
.breadcrumbs {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
}

.breadcrumbs li:not(:last-child):after {
  content: ">";
  color: var(--color-text-muted);
  padding: 0 0.25rem;
}

.breadcrumbs .breadcrumb-item,
.breadcrumbs .breadcrumb-item a {
  white-space: nowrap;
}

/* ==========================================================================
   =.btns
   ========================================================================== */
a.btn {
  display: inline-block;
  padding: 0.25rem 1.75rem;
  border-radius: var(--border-radius);
  background-color: var(--color-accent-1);
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  margin-bottom: 0.5rem;
}

a.btn-block {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

a.btn-large {
  padding: 0.5rem 1.75rem;
}

a.btn:hover {
  background-color: var(--color-accent-2);
  color: #ffffff;
}

a.btn i:first-child {
  margin-right: 0.5rem;
}

a.btn i:last-child {
  margin-left: 0.5rem;
}

/* ==========================================================================
   =Cards
   ========================================================================== */
.card {
  margin-bottom: 2rem;
  background-color: #ffffff;
  border-radius: var(--border-radius);
  box-shadow: 0px 12px 23px 0px rgba(0, 0, 0, 0.04);
}

.card-header {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid var(--color-border);
}

.card-body {
  padding: 1rem 1rem 1.5rem;
}

.card-body > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 360px) {
  .card-header {
    padding: 1.5rem;
  }
  .card-body {
    padding: 1.5rem;
  }
}
/* ==========================================================================
   =Data Tables
   ========================================================================== */
.data-table-container {
  overflow-x: auto;
  /* Enable horizontal scrolling on narrow screens */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
}

.data-table th,
.data-table td {
  padding: 1rem;
  text-align: left;
}

.data-table th {
  background-color: var(--color-accent-2);
  vertical-align: bottom;
  color: #ffffff;
  border: 0;
}

.data-table tr:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.data-table tr:nth-child(even) {
  background-color: var(--color-background);
}

.data-table td {
  vertical-align: middle;
  border: 0;
}

.data-table .btn,
.data-table button {
  padding: 0.125rem 0.5rem;
  margin-bottom: 0.25rem;
}

.data-table .btn i:first-child,
.data-table button i {
  margin-right: 0;
}

.data-table .btn span,
.data-table button span {
  display: none;
}

@media screen and (max-width: 767px) {
  /* On screens with width less than 768px (e.g., mobile devices) */
  .data-table thead {
    display: none;
  }
  .data-table tr {
    display: block;
    padding: 1rem;
  }
  .data-table td {
    display: block;
    border: none;
    padding: 0;
  }
  .data-table td:not(:last-child) {
    margin-bottom: 1rem;
  }
  .data-table button,
.data-table .btn {
    padding: 0.125rem 0.5rem;
  }
  .data-table button span,
.data-table .btn span {
    display: inline;
  }
}
/* ==========================================================================
   =Filter listings
   ========================================================================== */
.filter-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 27, 0.75);
  z-index: 9999;
}

.filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  background-color: #fff;
  z-index: 10000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.filter hr {
  margin: 1rem -1rem;
}

.filter label {
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  padding: 0.5rem 1rem;
}

.filter-close {
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--color-accent-1);
}

.filter-close:hover {
  color: var(--color-accent-2);
}

.filter-content {
  overflow-y: auto;
  padding: 1rem;
}

/* Active filters */
.filters-active {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.filters-active li {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.filters-active a {
  background-color: var(--color-accent-1);
  color: #ffffff;
  display: inline-block;
  padding: 0.125rem 0.5rem 0.125rem 0.5rem;
  text-decoration: none;
  font-size: 0.75rem;
}

.filters-active a:hover {
  background-color: var(--color-accent-2);
}

@media (min-width: 768px) {
  .filters-active li {
    margin-right: 0.5rem;
  }
  .filters-active a {
    padding: 0.25rem 1rem;
    font-size: 0.85rem;
  }
}
/* ==========================================================================
   =Locations
   ========================================================================== */
.locations ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.locations li {
  flex-basis: 50%;
  padding: 0 0 0.5rem 0;
}

.locations li a {
  display: block;
  text-decoration: none;
}

.locations li a span {
  font-size: 0.85rem;
  color: var(--color-text);
}

@media (min-width: 768px) {
  .locations li {
    flex-basis: 33.33333333%;
  }
}
@media (min-width: 1200px) {
  .locations li {
    flex-basis: 25%;
  }
}
/* ==========================================================================
   =Modals
   ========================================================================== */
.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 27, 0.98);
  z-index: 9999;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 3.5rem 1.75rem 1.75rem;
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  width: 90%;
  max-width: 520px;
}

.modal-content {
  max-height: 500px;
  overflow-y: auto;
}

.modal-close {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--color-accent-1);
}

.modal-close:hover {
  color: var(--color-accent-2);
}

/* ==========================================================================
   =Pagination
   ========================================================================== */
.pagination {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.pagination li {
  margin: 0 0.5rem 0.25rem 0;
}

.pagination li a {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  line-height: 2rem;
  display: block;
  padding: 0 0.5rem;
  text-decoration: none;
  min-width: 2.35rem;
  text-align: center;
}

.pagination li a span {
  display: none;
}

.pagination li:hover a {
  background-color: var(--color-accent-1);
  border-color: var(--color-accent-1);
  color: #ffffff;
}

.pagination li.active a {
  background-color: var(--color-accent-2);
  border-color: var(--color-accent-2);
  color: #ffffff;
}

@media (min-width: 360px) {
  .pagination li a {
    padding: 0 0.75rem;
  }
}
@media (max-width: 575px) {
  .pagination {
    justify-content: space-between;
  }
  .pagination li {
    display: none;
  }
  .pagination li.page-indicator {
    display: list-item;
  }
  .pagination li a span {
    display: inline;
  }
}
/* ==========================================================================
   =Profile Gallery
   ========================================================================== */
.profile-gallery a {
  display: block;
  position: relative;
  height: 400px;
}

.profile-gallery img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}

.profile-gallery i {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  border-radius: var(--border-radius);
  background-color: #ffffff;
  padding: 0.35rem;
  z-index: 900;
  text-decoration: none;
  font-size: 1.25rem;
}

.profile-gallery .swiper-pagination {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
  padding: 0.25rem 0 1rem 0;
}

.profile-gallery .swiper-pagination .swiper-pagination-bullet {
  background: var(--color-accent-1);
  height: 10px;
  width: 10px;
}

.profile-gallery .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--color-accent-2);
}

@media (min-width: 360px) {
  .profile-gallery a {
    height: 500px;
  }
}
@media (min-width: 576px) {
  .profile-gallery .swiper-pagination {
    padding: 1rem 0 1rem 0;
  }
}
/* ==========================================================================
   =Profile Preview
   ========================================================================== */
.profiles-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem 1rem;
}

.profiles-list li {
  flex-basis: 50%;
  padding: 0.5rem 0.5rem 0;
}

.profile-preview {
  text-decoration: none;
  text-align: center;
}

.profile-preview-image {
  height: 215px;
  position: relative;
  padding: 0.5rem 0 0.5rem;
}

.profile-preview-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}

.profile-preview-image .premium {
  position: absolute;
  top: -0.125rem;
  left: 0.75rem;
  background-color: var(--color-accent-1);
  color: #ffffff;
  font-size: 1rem;
  padding: 0.25rem;
  border-radius: var(--border-radius);
}

.profile-preview-image .verified {
  position: absolute;
  bottom: 1rem;
  left: -0.25rem;
  line-height: 1.25;
}

.profile-preview-image .inactive {
  position: absolute;
  top: 1rem;
  right: -0.25rem;
  line-height: 1.25;
}

.profile-preview-name {
  color: var(--color-accent-1);
  font-size: 1.125rem;
}

.profile-preview:hover .profile-preview-name {
  color: var(--color-accent-2);
}

.profile-preview-location {
  color: var(--color-text);
  font-size: 0.75rem;
  text-transform: uppercase;
}

@media (min-width: 360px) {
  .profile-preview-image {
    height: 250px;
  }
}
@media (min-width: 768px) {
  .profiles-list li {
    flex-basis: 50%;
  }
  .profile-preview-image {
    height: 407px;
  }
}
@media (min-width: 992px) {
  .profiles-list li {
    flex-basis: 33.33333333%;
  }
  .profile-preview-name {
    font-size: 1.424rem;
  }
  .profile-preview-image .verified {
    position: absolute;
    bottom: 1.5rem;
    left: -0.5rem;
    line-height: 1.5;
  }
  .profile-preview-image .inactive {
    position: absolute;
    top: 1.5rem;
    right: -0.5rem;
    line-height: 1.5;
  }
}
@media (min-width: 1200px) {
  .profiles-list li {
    flex-basis: 25%;
  }
  .profile-preview-image {
    height: 390px;
  }
  .profile-preview-image .premium {
    top: -0.125rem;
    left: 0.75rem;
    font-size: 1.25rem;
    padding: 0.25rem 0.5rem;
  }
}
@media (min-width: 1400px) {
  .profile-preview-image {
    height: 452px;
  }
}
/* ==========================================================================
   =Profile Sticky
   ========================================================================== */
#profile-sticky {
  color: var(--color-text);
  background-color: var(--color-accent-4);
  padding: 0.75rem 0.5rem 0.75rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  font-size: 0.85rem;
  text-transform: uppercase;
}

#profile-sticky a {
  text-decoration: none;
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  #profile-sticky {
    padding: 1rem 0 1rem;
  }
}
@media (min-width: 1200px) {
  #profile-sticky {
    display: none;
  }
}
/* ==========================================================================
   =Reviews
   ========================================================================== */
.review {
  margin-bottom: 1.5rem;
}

.review-title {
  font-weight: 700;
}

.review-details {
  padding: 1rem;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  margin-top: 1rem;
  position: relative;
  background-color: #ffffff;
}

.review-user-and-date {
  color: var(--color-muted);
  margin-top: 0.5rem;
}

/* Overlay for not logged in users*/
#review-form-container {
  position: relative;
}

#review-form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(24, 24, 27, 0.75);
  border-radius: var(--border-radius);
  z-index: 900;
}

#review-form-login-prompt {
  background-color: #fff;
  padding: 1.75rem;
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  width: 240px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ==========================================================================
   =Side Nav
   ========================================================================== */
.side-nav {
  list-style: none;
  margin-bottom: 0;
}

.side-nav li {
  padding-bottom: 0.5rem;
}

.side-nav li a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
}

.side-nav li a:hover {
  background-color: var(--color-accent-1);
  border-color: var(--color-accent-1);
  color: #ffffff;
}

.side-nav li a.active {
  background-color: var(--color-accent-2);
  border-color: var(--color-accent-2);
  color: #ffffff;
}

.side-nav li a i {
  margin-right: 1rem;
}

.side-nav li a span {
  background-color: var(--color-accent-1);
  padding: 0.125rem 0.5rem;
  border-radius: var(--border-radius);
  margin-left: 0.5rem;
  color: #ffffff;
  line-height: 1;
  font-size: 0.75rem;
}

.side-nav li a:hover span,
.side-nav li a.active span {
  background-color: #ffffff;
  color: var(--color-link);
}

/* ==========================================================================
   =Widgets
   ========================================================================== */
.widget {
  margin-bottom: 1.5rem;
}

#footer div[class^=col]:last-child .widget:last-child {
  margin-bottom: 0;
}

.widget-title {
  cursor: pointer;
}

.widget-title span {
  margin: 0;
}

.widget-title i {
  background-color: var(--color-background);
  border-radius: 50%;
  padding: 0.25rem;
}

.widget-title + .widget-content {
  padding-top: 1.5rem;
}

.widget-content *:last-child {
  margin-bottom: 0;
}

.widget a {
  text-decoration: none;
}

/* =Colapsible Widgets
   ========================================================================== */
.widget-colapsible .widget-content {
  display: none;
}

@media (min-width: 576px) {
  .widget-colapsible .widget-title {
    cursor: default !important;
  }
  .widget-colapsible .widget-title i {
    display: none !important;
  }
  .widget-colapsible .widget-content {
    display: block !important;
  }
}
/* =Menu Widget
   ========================================================================== */
.widget-menu ul {
  list-style: none;
}

.widget-menu li {
  padding: 0.25rem 0;
}

.widget-menu li:first-child {
  padding-top: 0;
}

.widget-menu li:last-child {
  padding-bottom: 0;
}

.widget-menu a {
  line-height: 1.75;
}

.widget-menu a i {
  color: var(--color-accent-1);
  padding-right: 0.25rem;
}

.widget-menu a small {
  padding: 0.125rem 0.25rem;
  background-color: var(--color-accent-1);
  color: #ffffff;
  border-radius: 0.25rem;
  margin-left: 0.25rem;
  font-size: 0.75rem;
}

/* ==========================================================================
   =Utilities
   ========================================================================== */
.color-success {
  color: var(--color-success) !important;
}

.color-muted {
  color: var(--color-muted) !important;
}

.color-warning {
  color: var(--color-warning) !important;
}

.color-danger {
  color: var(--color-danger) !important;
}

.bg-color-accent-1 {
  background-color: var(--color-accent-1) !important;
}

.color-accent-1 {
  color: var(--color-accent-1) !important;
}

.bg-color-accent-2 {
  background-color: var(--color-accent-2) !important;
}

.bg-color-accent-3 {
  background-color: var(--color-accent-3) !important;
}

.bg-color-success {
  background-color: var(--color-success) !important;
}

.bg-color-background {
  background-color: var(--color-background) !important;
}

.bg-color-danger {
  background-color: var(--color-danger) !important;
}

.bg-color-whatsapp {
  background-color: var(--color-whatsapp) !important;
}

.vh-100 {
  height: 100vh !important;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.width-110px {
  width: 110px;
}

.width-160px {
  width: 160px;
}

.width-50px {
  width: 100px;
}