/* ==========================================================================
   =Reset (minimal)
   ========================================================================== */

	*,
	*::before,
	*::after {
	  box-sizing: border-box;
	}

	@media (prefers-reduced-motion: no-preference) {
	  :root {
		scroll-behavior: smooth;
	  }
	}
	
	body {
		margin: 0;
		-webkit-text-size-adjust: 100%;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);	
	}

	hr {
		margin: 1rem 0;
		color: inherit;
		border: 0;
		border-top: 1px solid;
		opacity: 1;
	}	
	
	p {
		margin-top: 0;
		margin-bottom: 1rem;
	}
	
	ol,
	ul {
		padding-left: 0;
		list-style-position: inside;
	}

	ol,
	ul,
	dl {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	ol ol,
	ul ul,
	ol ul,
	ul ol {
		margin-bottom: 0;
		padding-left: 2rem;
	}
	
	sub,
	sup {
		position: relative;
		font-size: 0.75em;
		line-height: 0;
		vertical-align: baseline;
	}

	sub {
		bottom: -0.25em;
	}

	sup {
		top: -0.5em;
	}
	
	figure {
		margin: 0 0 1rem;
	}

	img,
	svg {
		vertical-align: middle;
	}

	table {
		caption-side: bottom;
		border-collapse: collapse;
	}

	caption {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		text-align: left;
	}

	th {
		text-align: inherit;
		text-align: -webkit-match-parent;
	}

	thead,
	tbody,
	tfoot,
	tr,
	td,
	th {
		border-color: inherit;
		border-style: solid;
		border-width: 0;
	}
	
	button {
		border-radius: 0;
	}

	button:focus:not(:focus-visible) {
		outline: 0;
	}

	input,
	button,
	select,
	optgroup,
	textarea {
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
	}

	button,
	select {
		text-transform: none;
	}

	[role=button] {
		cursor: pointer;
	}

	select {
		word-wrap: normal;
	}
	
	select:disabled {
		opacity: 1;
	}
	
	button,
	[type=button],
	[type=reset],
	[type=submit] {
		-webkit-appearance: button;
	}
	
	button:not(:disabled),
	[type=button]:not(:disabled),
	[type=reset]:not(:disabled),
	[type=submit]:not(:disabled) {
		cursor: pointer;
	}

	::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}

	textarea {
		resize: vertical;
	}

	fieldset {
		min-width: 0;
		padding: 0;
		margin: 0;
		border: 0;
	}
	
	::-webkit-datetime-edit-fields-wrapper,
	::-webkit-datetime-edit-text,
	::-webkit-datetime-edit-minute,
	::-webkit-datetime-edit-hour-field,
	::-webkit-datetime-edit-day-field,
	::-webkit-datetime-edit-month-field,
	::-webkit-datetime-edit-year-field {
		padding: 0;
	}

	::-webkit-inner-spin-button {
		height: auto;
	}

	[type=search] {
		outline-offset: -2px;
		-webkit-appearance: textfield;
	}

	::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	::-webkit-color-swatch-wrapper {
		padding: 0;
	}

	::-webkit-file-upload-button {
		font: inherit;
		-webkit-appearance: button;
	}

	::file-selector-button {
		font: inherit;
		-webkit-appearance: button;
	}

	output {
		display: inline-block;
	}

	iframe {
		border: 0;
	}

	summary {
		display: list-item;
		cursor: pointer;
	}

	progress {
		vertical-align: baseline;
	}

	[hidden] {
		display: none !important;
	}
	