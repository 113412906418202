/* ==========================================================================
   =Alerts
   ========================================================================== */

    .alert {
        margin-bottom: 1rem;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius);
        padding: 1rem;    
    }

    .alert i {
        color: var(--color-accent-1);
        margin-right: 0.25rem;
    }

    .alert ul {
        list-style: none;
    }