/* ==========================================================================
   =Locations
   ========================================================================== */

   .locations ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
   }

   .locations li {
      flex-basis: 50%;
      padding: 0 0 0.5rem 0;
   }

   .locations li a {
      display: block;
      text-decoration: none;
   }

   .locations li a span {
      font-size: 0.85rem;
      color: var(--color-text);
   }   

   @media(min-width: 768px) {

      .locations li {
         flex-basis: 33.33333333%;
      }

   }

   @media(min-width: 1200px) {

      .locations li {
         flex-basis: 25%;
      }

   }