/* ==========================================================================
   =Profile Preview
   ========================================================================== */

   .profiles-list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.5rem 1rem;
   }

   .profiles-list li {
      flex-basis: 50%;
      padding: 0.5rem 0.5rem 0;
   }

   .profile-preview {
      text-decoration: none;
      text-align: center;
   }

   .profile-preview-image {
      height: 215px;
      position: relative;
      padding: 0.5rem 0 0.5rem;
   }

   .profile-preview-image img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
   }

   .profile-preview-image .premium {
      position: absolute;
      top: -0.125rem;
      left: 0.75rem;
      background-color: var(--color-accent-1);
      color: #ffffff;
      font-size: 1rem;
      padding: 0.25rem;
      border-radius: var(--border-radius);
   }

   .profile-preview-image .verified {
      position: absolute;
      bottom: 1rem;
      left: -0.25rem;
      line-height: 1.25;
   }

   .profile-preview-image .inactive {
      position: absolute;
      top: 1rem;
      right: -0.25rem;
      line-height: 1.25;
   }

   .profile-preview-name {
      color: var(--color-accent-1);
      font-size: 1.125rem;
   }

   .profile-preview:hover .profile-preview-name {
      color: var(--color-accent-2);
   }

   .profile-preview-location {
      color: var(--color-text);
      font-size: 0.75rem;
      text-transform: uppercase;
   }

   @media(min-width: 360px) {

      .profile-preview-image {
         height: 250px;
      }   

   }

   @media(min-width: 768px) {

      .profiles-list li {
         flex-basis: 50%;
      }

      .profile-preview-image {
         height: 407px;
      }   

   }

   @media(min-width: 992px) {

      .profiles-list li {
         flex-basis: 33.33333333%;
      }

      .profile-preview-name {
         font-size: 1.424rem;
      }

      .profile-preview-image .verified {
         position: absolute;
         bottom: 1.5rem;
         left: -0.5rem;
         line-height: 1.5;
      }

      .profile-preview-image .inactive {
         position: absolute;
         top: 1.5rem;
         right: -0.5rem;
         line-height: 1.5;
      }

     

   }

   @media(min-width: 1200px) {

      .profiles-list li {
         flex-basis: 25%;
      }

      .profile-preview-image {
         height: 390px;
      }  

      .profile-preview-image .premium {
         top: -0.125rem;
         left: 0.75rem;
         font-size: 1.25rem;
         padding: 0.25rem 0.5rem;
      }

   }

   @media(min-width: 1400px) {

      .profile-preview-image {
         height: 452px;
      }  

   }   