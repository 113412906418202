/* ==========================================================================
   =Badges
   ========================================================================== */

   .badge {
      color: #ffffff;
      border-radius: var(--border-radius);
      padding: 0 0.5rem;
      background-color: var(--color-accent-1);
      line-height: 1.5;
   }
   
  