/* ==========================================================================
   =Profile Gallery
   ========================================================================== */

    .profile-gallery a {
        display: block;
        position: relative;
        height: 400px;
    }

    .profile-gallery img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
    }

  
    .profile-gallery i {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        border-radius: var(--border-radius);
        background-color: #ffffff;
        padding: 0.35rem;
        z-index: 900;
        text-decoration: none;
        font-size: 1.25rem;
    }

    .profile-gallery .swiper-pagination {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        padding: 0.25rem 0 1rem 0;
    }

    .profile-gallery .swiper-pagination .swiper-pagination-bullet {
        background: var(--color-accent-1);
        height: 10px;
        width: 10px;
    }

    .profile-gallery .swiper-pagination .swiper-pagination-bullet-active {
        background: var(--color-accent-2);
    }

    @media(min-width: 360px) {

        .profile-gallery a {
            height: 500px; 
        }

    }

    @media(min-width: 576px) {

        .profile-gallery .swiper-pagination {
            padding: 1rem 0 1rem 0;
        }       

    }