/* ==========================================================================
   =Cards
   ========================================================================== */

    .card {
        margin-bottom: 2rem;
        background-color: #ffffff;
        border-radius: var(--border-radius);
        box-shadow: 0px 12px 23px 0px rgba(0,0,0,0.04);
    }

    .card-header {
        padding: 1.5rem 1rem;
        border-bottom: 1px solid var(--color-border);
    }

    .card-body {
        padding: 1rem 1rem 1.5rem;
    }

    .card-body > *:last-child {
        margin-bottom: 0;
    }

    @media(min-width: 360px){

		.card-header { 
			padding: 1.5rem;
		}

        .card-body { 
			padding: 1.5rem;
		}

	}
   