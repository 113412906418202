/* ==========================================================================
   =Utilities
   ========================================================================== */

    .color-success {
        color: var(--color-success) !important;
    }

    .color-muted {
        color: var(--color-muted) !important;
    }

    .color-warning {
        color: var(--color-warning) !important;
    }

    .color-danger {
        color: var(--color-danger) !important;
    }

    .bg-color-accent-1 {
        background-color: var(--color-accent-1) !important;
    }  

    .color-accent-1 {
        color: var(--color-accent-1) !important;
    }  
    
    .bg-color-accent-2 {
        background-color: var(--color-accent-2) !important;
    }  

    .bg-color-accent-3 {
        background-color: var(--color-accent-3) !important;
    }  

    .bg-color-success {
        background-color: var(--color-success) !important;
    }

    .bg-color-background {
        background-color: var(--color-background) !important;
    }

    .bg-color-danger {
        background-color: var(--color-danger) !important;
    }

    .bg-color-whatsapp {
        background-color: var(--color-whatsapp) !important;
    }

    .vh-100 {
        height: 100vh !important;
    }

    .h-100 {
        height: 100% !important;
    }

    .w-100 {
        width: 100% !important;
    }

    .text-center {
        text-align: center;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .hidden {
        display: none !important;
        visibility: hidden;
    }
    
    .width-110px {
        width: 110px;
    }

    .width-160px {
        width: 160px;
    }
    
    .width-50px {
        width: 100px;
    }