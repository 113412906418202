/* ==========================================================================
   =Widgets
   ========================================================================== */

    .widget {
        margin-bottom: 1.5rem;
    }

    #footer div[class^="col"]:last-child .widget:last-child {
        margin-bottom: 0;
    }

    .widget-title {
        cursor: pointer;
    }

    .widget-title span {
        margin: 0;
    }

    .widget-title i {
        background-color: var(--color-background);
        border-radius: 50%;
        padding: 0.25rem;
    }
    
    .widget-title + .widget-content {
        padding-top: 1.5rem;
    }

    .widget-content *:last-child {
        margin-bottom: 0;
    }

    .widget a {
        text-decoration: none;
    }

/* =Colapsible Widgets
   ========================================================================== */

    .widget-colapsible .widget-content {
        display: none;
    }

    @media (min-width: 576px) {

        .widget-colapsible .widget-title {
            cursor: default !important;
        }

        .widget-colapsible .widget-title i {
            display: none !important;
        }

        .widget-colapsible .widget-content {
            display: block !important;
        }

    }

/* =Menu Widget
   ========================================================================== */

    .widget-menu ul {
        list-style: none;
    }

    .widget-menu li {
        padding: 0.25rem 0;
    }

    .widget-menu li:first-child {
        padding-top: 0;
    }

    .widget-menu li:last-child {
        padding-bottom: 0;
    }

    .widget-menu a {
        line-height: 1.75;
    }

    .widget-menu a i {
        color: var(--color-accent-1);
        padding-right: 0.25rem;
    }

    .widget-menu a small {
        padding: 0.125rem 0.25rem;
        background-color: var(--color-accent-1);
        color: #ffffff;
        border-radius: 0.25rem;
        margin-left: 0.25rem;
        font-size: 0.75rem;
    }
