/* ==========================================================================
   =.btns
   ========================================================================== */

    a.btn {
      	display: inline-block;
		padding: 0.25rem 1.75rem;		
		border-radius: var(--border-radius);
		background-color: var(--color-accent-1);
		color: #ffffff;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		appearance: none;
      	margin-bottom: 0.5rem;
	}
	
    a.btn-block {
		display: block;
		width: 100%;
		margin-bottom: 1rem;
    }

    a.btn-large {
		padding: 0.5rem 1.75rem;
    }

	a.btn:hover {
		background-color: var(--color-accent-2);
		color: #ffffff;
	}

	a.btn i:first-child {
		margin-right: 0.5rem;
	}

	a.btn i:last-child {
		margin-left: 0.5rem;
   	}
