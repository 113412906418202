/* ==========================================================================
   =Forms
   ========================================================================== */

	::placeholder { 
		color: var(--color-placeholders); 
	} 

	fieldset { 
		border: 1px solid var(--color-border);
		border-radius: var(--border-radius);
		padding: 0.5rem 1rem;
		margin-bottom: 1rem;
	}
	
	.form-group {
		margin-bottom: 1rem;
	}

	label {
		display: block;
		margin-bottom: 0.25rem;
		color: var(--color-labels);
	}
	
	label + label {
		margin-top: -0.125rem;
	}
	
	@media(min-width: 768px) {

		fieldset {
			padding: 2rem 2rem;
		} 

	}

	@media(min-width: 360px){

		fieldset { 
			padding: 1.5rem 1.5rem;
		}

	}

/* =Inputs and Text Areas
   ========================================================================== */
	
	input:not([type="checkbox"]):not([type="radio"]),
	textarea {
		display: block;
		width: 100%;
		border-radius: var(--border-radius);
		padding: 0.25rem 0.75rem;
		border: 1px solid var(--color-border-forms);
		color: var(--color-text);
		appearance: none;
		background-clip: padding-box;
	}
	
	input[type="file"] {
		padding: 0 !important;
		border: 0 !important;
	}

	textarea[rows] { height: auto; }
	
	input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):focus,
	textarea:focus {
		border-color: var(--color-border-forms-focus);
		outline: 1px solid var(--color-border-forms-focus);
	}
	
	input[type="file"]:focus {
		outline: 0;
	}
	
	input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):hover,
	textarea:hover {
		border-color:  var(--color-border-forms-focus);
	}

/* =Select
   ========================================================================== */

	select {
		display: block;
		width: 100%;
		padding: 0.25rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.75;
		color: var(--color-text);
		background-color: #ffffff;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-position: right 0.75rem center;
		background-size: 1rem 0.75rem;
		border: 1px solid var(--color-border-forms);
		border-radius: var(--border-radius);
		appearance: none;
		background-clip: padding-box;
		cursor: pointer;
	}
	
	select[multiple], 
	select[size]:not([size="1"]){
		padding-right: 0.75rem;
		background-image: none;
	}
	
	select:hover {
		border-color: var(--color-border-forms-focus);
	}
	select:focus {
		border-color: var(--color-border-forms-focus);
		outline: 1px solid var(--color-border-forms-focus);
	}

/* =Buttons
   ========================================================================== */

	button {
		padding: 0.3rem 1.75rem;		
		border-radius: var(--border-radius);
		background-color: var(--color-accent-1);
		color: #ffffff;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		appearance: none;
		border: 0;
		margin-bottom: 0.5rem;
	}
	
	button.btn-block {
		display: block;
		width: 100%;
		margin-bottom: 1rem;
    }

	button.btn-large {
		padding: 0.5rem 1.75rem;
    }

	button:hover {
		background-color: var(--color-accent-2);
	}

	button i:first-child {
		margin-right: 0.5rem;
	}

	button i:last-child {
		margin-left: 0.5rem;
	}

/* =Dropzone
   ========================================================================== */
	
   

/* =Validation
   ========================================================================== */

    .required:after {
		content: "*";
		position: relative;
		font-size: inherit;
		color: red;
		font-weight: 700;
	}

	.err {
		color: var(--color-danger);
		font-size: 0.85rem;
	}

	.is-invalid {
		border-color: var(--color-danger) !important;
		outline-color: var(--color-danger) !important;
	}	
