/* ==========================================================================
   =Modals
   ========================================================================== */

   .modal-overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(24, 24, 27, 0.98);
      z-index: 9999;
   }
    
   .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 3.5rem 1.75rem 1.75rem;
      border-radius: var(--border-radius);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      width: 90%;
      max-width: 520px;
   }
    
   .modal-content {
      max-height: 500px;
      overflow-y: auto;
   }


   .modal-close {
      position: absolute;
      top: 0.5rem;
      right: 1.5rem;
      font-size: 1.5rem;
      cursor: pointer;
      color: var(--color-accent-1);
   }

   .modal-close:hover {
      color: var(--color-accent-2);
   }


    
  