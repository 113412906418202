/* ==========================================================================
   =Side Nav
   ========================================================================== */

    .side-nav {
        list-style: none;
        margin-bottom: 0;
    }

    .side-nav li {
        padding-bottom: 0.5rem;
    }

    .side-nav li a {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius);
    }

    .side-nav li a:hover {
        background-color: var(--color-accent-1);
        border-color: var(--color-accent-1); 
        color: #ffffff;
    }

    .side-nav li a.active {
        background-color: var(--color-accent-2);
        border-color: var(--color-accent-2); 
        color: #ffffff;
    }

    .side-nav li a i {
        margin-right: 1rem;
    }

    .side-nav li a span {
        background-color: var(--color-accent-1);
        padding: 0.125rem 0.5rem;
        border-radius: var(--border-radius);
        margin-left: 0.5rem;
        color: #ffffff;
        line-height: 1;
        font-size: 0.75rem;
    }

    .side-nav li a:hover span,
    .side-nav li a.active span {
        background-color: #ffffff;
        color: var(--color-link)
    }