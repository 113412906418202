/* ==========================================================================
   =Body
   ========================================================================== */

    body.admin {
        background-color: var(--color-background);
    }

/* ==========================================================================
   =Header
   ========================================================================== */

    #header {
        padding: 0 0.5rem;
        color: var(--color-text);
        background-color: var(--color-accent-3);
        height: 4.5rem;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid rgba(255,255,255,0.25);
        z-index: 1000;
    }

    @media (min-width: 576px) {

        #header {
            padding: 0;
            height: 5rem;
        }   
        
    }

    @media (min-width: 1200px) {

        #header {
            height: 5rem;
        }  

    }

/* =Favorites
   ========================================================================== */

    #user-favorites-trigger {
        text-decoration: none;
        margin-right: 1.25rem;
    }

    #user-favorites-trigger i {
        font-size: 1.5rem;
    }

    #user-favorites-trigger span {
        display: none;
    }

    @media (min-width: 576px) {

        #user-favorites-trigger span {
            display: inline-block;
            padding: 0 0 0 0.5rem;
        }

    }

/* =Account Login
   ========================================================================== */

    #user-account-trigger {
        text-decoration: none;
    }

    #user-account-trigger i {
        font-size: 1.5rem;
    }

    #user-account-trigger span {
        display: none;
    }    

    @media (min-width: 576px) {

        #user-account-trigger span {
            display: inline-block;
            padding: 0 0 0 0.5rem;
        }   
        
    }
    
/* =Register Account
   ========================================================================== */

    #user-register-trigger {
        text-decoration: none;
        margin-left: 1.25rem;
        padding: 0.2rem 0.75rem;
        border-radius: var(--border-radius);
        background-color: var(--color-accent-1);
        color: #ffffff;
    }

    #user-register-trigger:hover {
        background-color: var(--color-accent-2);
    }

    #user-register-trigger i {
        font-size: 1.5rem;
    }
            
    #user-register-trigger span {
        display: inline-block;
        padding: 0 0 0 0.5rem;
    }   

/* ==========================================================================
   =Content
   ========================================================================== */

    #content {
        margin-top: 4.5rem;
        padding: 1.5rem 0.5rem 1rem;
    }

    .admin #content {
        padding: 0 0.5rem 0;
        margin-top: 0;
    }

    @media (min-width: 576px) {
    
        #content {
            margin-top: 5rem;
            padding: 2.5rem 0 2rem;
        }

        .admin #content {
            padding: 0;
        }

    }

    @media (min-width: 1200px) {

        #content {
            margin-top: 5rem;
        }

    }

/* =Page Header
   ========================================================================== */

    #page-header {
        margin: -1.5rem -0.5rem 1.5rem;
        padding: 1.5rem 0.5rem 1.5rem;
        background-color: var(--color-accent-1);
    }

    #page-header h1 {
        margin: 0;
    }

    @media (min-width: 576px) {
    
        #page-header {
            margin: -2.5rem 0 2rem;
            padding: 1.5rem 0 1.5rem;
        }    

    }        

    @media (min-width: 1200px) {

        #page-header {
            margin: -2.5rem 0 2.5rem;
            padding: 2rem 0 2rem;
        }

    }

/* =CTA
   ========================================================================== */

    #cta {
        margin: -1.5rem -0.5rem 1.5rem;
        padding: 1rem 0.5rem 1rem;
        background-color: var(--color-accent-3);
    }

    #cta .btn {
        margin-bottom: 0;
    }

    @media (min-width: 576px) {

        #cta {
            margin: -2rem 0 2rem;
            padding: 1.5rem 0 1.5rem;
        }    

    }        

    @media (min-width: 768px) {

        #cta {
            display: none;
        }

    }

/* =Locations
   ========================================================================== */

    #locations {
        background-color: var(--color-background);
        margin: -1.5rem -0.5rem 1.5rem;
        padding: 1.5rem 0.5rem 0.5rem;
    }

    @media (min-width: 576px) {
    
        #locations {
            margin: -2rem 0 2rem;
            padding: 2.5rem 0 1.5rem;
        }    

    }        

    @media (min-width: 1200px) {

        #locations {
            margin: -2.5rem 0 2.5rem;
            padding: 3rem 0 2rem;
        }

    }

/* ==========================================================================
   =Footer & Copyright
   ========================================================================== */   

/* =Footer
   ========================================================================== */

    #footer {
        padding: 2.5rem 0.5rem;
        color: var(--color-text);
        background-color: var(--color-accent-3);
    }

    @media (min-width: 576px) {

        #footer {
            padding: 3.5rem 0 1.5rem;
        }   
        
    }

/* =Copyright
   ========================================================================== */

    #copyright {
        padding: 1rem 0;
        color: var(--color-text);
        background-color: var(--color-accent-4);   
    }

    @media (min-width: 1200px) {

        #copyright {
            text-align: center;
        }  

    }
