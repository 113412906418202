/* ==========================================================================
   =Breadcrumbs
   ========================================================================== */

   .breadcrumbs {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1.5rem;
      font-size: 0.95rem;
   }

   .breadcrumbs li:not(:last-child):after {
      content: ">";
      color: var(--color-text-muted);
      padding: 0 0.25rem;
   }

   .breadcrumbs .breadcrumb-item,
   .breadcrumbs .breadcrumb-item a {
      white-space: nowrap;
   }   
