/* ==========================================================================
   =Reviews
   ========================================================================== */

    .review {
        margin-bottom: 1.5rem;
    }

    .review-title {
        font-weight: 700;
    }

    .review-details {
        padding: 1rem;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius);
        margin-top: 1rem;
        position: relative;
        background-color: #ffffff;
    }

    .review-user-and-date {
        color: var(--color-muted);
        margin-top: 0.5rem;
    }

    /* Overlay for not logged in users*/

    #review-form-container {
        position: relative;
    }

    #review-form-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(24, 24, 27, 0.75);
        border-radius: var(--border-radius);
        z-index: 900;
    }

    #review-form-login-prompt {
        background-color: #fff;
        padding: 1.75rem;
        border-radius: var(--border-radius);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        width: 240px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }