/* ==========================================================================
   =Data Tables
   ========================================================================== */

   .data-table-container {
      overflow-x: auto;
      /* Enable horizontal scrolling on narrow screens */
   }
   
   .data-table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid var(--color-border);
      border-radius: var(--border-radius);
   }
   
   .data-table th,
   .data-table td {
      padding: 1rem;
      text-align: left;
   }
   
   .data-table th {
      background-color: var(--color-accent-2);
      vertical-align: bottom;
      color: #ffffff;
      border: 0;
   }
   
   .data-table tr:not(:last-child) {
      border-bottom: 1px solid var(--color-border);
   }

   .data-table tr:nth-child(even) {
      background-color: var(--color-background);
   }
   
   .data-table td {
      vertical-align: middle;
      border: 0;
   }
   
   .data-table .btn,
   .data-table button {
      padding: 0.125rem 0.5rem;
      margin-bottom: 0.25rem;
   }

   .data-table .btn i:first-child,
   .data-table button i {
      margin-right: 0;
   }

   .data-table .btn span,
   .data-table button span {
      display: none;
   }
   
   @media screen and (max-width: 767px) {

      /* On screens with width less than 768px (e.g., mobile devices) */
      .data-table thead {
         display: none;
      }
   
      .data-table tr {
         display: block;
         padding: 1rem;
      }
   
      .data-table td {
         display: block;
         border: none;
         padding: 0;
      }

      .data-table td:not(:last-child) {
         margin-bottom: 1rem;
      }

      .data-table button,
      .data-table .btn {
         padding: 0.125rem 0.5rem;
      }

      .data-table button span,
      .data-table .btn span {
         display: inline;
      }

   }
   